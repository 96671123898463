
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { getUId } from '@/utils/UIdUtil';
import { Size } from '@/models/components/size.model';

export default defineComponent({
  name: 'InputTextArea',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<Size>,
      default: 'md',
    },
    rows: {
      type: Number,
      required: false,
      default: 4,
    },
    block: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    labelHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputId: Ref<string> = ref('');

    onMounted(() => {
      inputId.value = 'input-textarea-' + getUId();
    });

    function onChange(event: Event): void {
      const target = event.target as HTMLInputElement;
      emit('update:modelValue', target.value);
    }

    return {
      inputId,
      onChange,
    };
  },
});
