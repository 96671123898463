import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-textarea__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["input-textarea", [{'input-textarea--block': _ctx.block, 'input-textarea--error': _ctx.error}, 'input-textarea--' + _ctx.size]],
    title: _ctx.label
  }, [
    _createVNode("label", {
      class: ["input-textarea__label text--medium", {'sr-only': _ctx.labelHidden}],
      for: _ctx.inputId
    }, _toDisplayString(_ctx.label), 11, ["for"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("textarea", {
        class: ["input-textarea__input text-body--md", {'input-textarea__input--block': _ctx.block, 'input-textarea__input--error': _ctx.error}],
        type: "text",
        id: _ctx.inputId,
        name: _ctx.name,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        rows: _ctx.rows,
        disabled: _ctx.disabled,
        autocomplete: "off",
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 42, ["id", "name", "value", "placeholder", "rows", "disabled"])
    ])
  ], 10, ["title"]))
}