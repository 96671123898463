import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-text__container" }
const _hoisted_2 = {
  key: 0,
  class: "input-text__icon input-text__icon--prepend"
}
const _hoisted_3 = {
  key: 1,
  class: "input-text__icon input-text__icon--append"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("div", {
    class: ["input-text", [{'input-text--block': _ctx.block, 'input-text--error': _ctx.error}, 'input-text--' + _ctx.size]],
    title: _ctx.label
  }, [
    _createVNode("label", {
      class: ["input-text__label text--medium", {'sr-only': _ctx.labelHidden}],
      for: _ctx.inputId
    }, _toDisplayString(_ctx.label), 11, ["for"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("input", {
        class: ["input-text__input text-body--md", {'input-text__input--block': _ctx.block, 'input-text__input--error': _ctx.error, 'input-text__input--icon-prepend': _ctx.prependIcon, 'input-text__input--icon-append': _ctx.appendIcon}],
        type: _ctx.search ? 'search' : 'text',
        id: _ctx.inputId,
        name: _ctx.name,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocompleteValue,
        disabled: _ctx.disabled,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 42, ["type", "id", "name", "value", "placeholder", "autocomplete", "disabled"]),
      (_ctx.prependIcon)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode(_component_Icon, {
              name: _ctx.prependIcon,
              size: _ctx.iconSize
            }, null, 8, ["name", "size"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.appendIcon)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              name: _ctx.appendIcon,
              size: _ctx.iconSize
            }, null, 8, ["name", "size"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 10, ["title"]))
}