
import { computed, ComputedRef, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import Icon from '@/components/Icon/Icon.vue';
import { getUId } from '@/utils/UIdUtil';
import { Icons } from '@/models/components/icon.model';
import { Size } from '@/models/components/size.model';

export default defineComponent({
  name: 'InputText',
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    prependIcon: {
      type: String as PropType<Icons>,
      default: undefined,
    },
    appendIcon: {
      type: String as PropType<Icons>,
      default: undefined,
    },
    size: {
      type: String as PropType<Size>,
      default: 'md',
    },
    block: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    labelHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputId: Ref<string> = ref('');
    const autocompleteValue: ComputedRef<string> = computed(() => props.autocomplete ? 'on' : 'off');
    const iconSize: ComputedRef<string> = computed(() => props.size === 'sm' ? 'xs' : props.size === 'md' ? 'sm' : 'md');
      
    onMounted(() => {
      inputId.value = 'input-text-' + getUId();
    });

    function onChange(event: Event): void {
      const target = event.target as HTMLInputElement;
      emit('update:modelValue', target.value);
    }

    return {
      inputId,
      autocompleteValue,
      iconSize,
      onChange,
    };
  },
});
